.ui-news-card {
    display: inline-flex !important;
    min-height: 370px !important;
    margin: 1em .5em !important;
}
.ui-news-card:first-child {
   margin: 1em .5em 1em .5em !important
}
.ui-news-card-image {
    max-width: 100%;
}

.ui.card > .image {
    max-height: 192px;
    overflow: hidden;
}

.item-enter {
    opacity: 0.05;
    margin-top: 203px !important;
    overflow: hidden;
}
.item-enter:first-child {
    opacity: 0.05;
    margin-top: 203px !important;
    overflow: hidden;
}
.item-enter-active {
    opacity: 1;
    transition: all .5s ease-in-out;
    margin-top: 1em !important;
}
.item-enter-active:first-child {
    opacity: 1;
    transition: all .5s ease-in-out;
    margin-top: 1em !important;
}
.item-leave {
  opacity: 1;
}
.item-leave-exit {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

@media  (min-width: 400px){
    .ui-news-card {
        width: auto !important ;
    }
    .ui.card > .image {
        max-height: 200px;
    }
}

@media  (min-width: 720px){
    .ui-news-card {
        width: 400px !important;
    }
    .ui.card > .image {
        max-height: 300px;
    }
}

