.flex-wrapper{
    width: 98%;
    margin: 0px auto; 
    display: flex;
    flex-wrap: wrap;
  }
  
  .news-articles-exit {
    margin-left: 0px;
    opacity: 0;
  }
  .news-articles-exit-active {
    margin-left: -2000px;
    transition: all 7s ease-out ;
    opacity: 1;
  }

 
@media (max-width: 500px){
    .flex-wrapper{
        display: block;
        max-width: 500px;
    }
}
